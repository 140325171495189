// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';
import { Environment } from '@app/interfaces';

export const environment: Environment = {
  production: true,
  version: env.npm_package_version,
  domain: 'testapi.afaqydev.site/',
  subDomain: '',
  serverUrl: 'https://testapi.afaqydev.site/api/v1/',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'ar-SA'],
  // listImagesPath: 'https://test.api.fleet.afaqy.co/',
  dummyDataLoader: false,
  // sentryPath: 'https://b397f986315b44d2bdf1b6d1bc6adf72@sentry.afaqy.co/3',
  // sentryEnvironmentName: 'Saas-Test',
  // saasEnvironmentSubdomain: 'test',
  // saasEnvironmentApiDomain: 'api.afaqy-fleet.site/api/v1/',
  // saasEnvironmentFrontDomain: 'afaqy-fleet.site',
  // pusher: {
  //   key: 'a578d0697475b5adfe97',
  //   options: {
  //     cluster: 'eu',
  //   },
  // },

  heatmapsConfig: {
    // serverUrl: 'http://localhost:3333/api/',
    serverUrl: 'https://dev.api.heatmaps.afaqy.co/api',
    env: 'test',
    project: 'afaqy-products-admin',
    userIdStorageKey: 'heatmapsUserId',
    userNameStorageKey: 'heatmapsUsername',
    submitActionsOnCount: 15,
    submitActionsOnInterval: 15000,
  },
  sensorStompConfig: {
    url: 'https://projects.afaqy.pro:12164/socket',
    headers: {},
    heartbeat_in: 0,
    heartbeat_out: 50000,
    reconnect_delay: 5000,
    debug: false,
  },
};
